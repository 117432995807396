import { graphql, Link, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import { Card, Col, Row } from "react-bootstrap"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SiteBackground from "../components/siteBackground"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
  query {
    ceramic: file(relativePath: { eq: "ceramic-icon.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    installation: file(relativePath: { eq: "installation-icon.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    performance: file(relativePath: { eq: "performance-icon.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sculpture: file(relativePath: { eq: "sculpture-icon.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`)
  return (
    <SiteBackground>
      <Layout hideCategories={true}>
        <SEO title="Home" />
        <div style={{ minHeight: "95vh" }}>
          <Row>
            <Col md={{span:6, offset:3}} >
              <Row>
                {categoryCard("sculptures", "Sculptures", data.sculpture.childImageSharp.fluid)}
                {categoryCard("installations", "Installations", data.installation.childImageSharp.fluid)}
                {categoryCard("performances", "Performances", data.performance.childImageSharp.fluid)}
                {categoryCard("ceramics", "Ceramics", data.ceramic.childImageSharp.fluid)}
              </Row>
            </Col>
          </Row>
        </div>
      </Layout>
    </SiteBackground>
  )
}

const categoryCard = (link, title, img) => (
  <Col md={6}>
    <Card className="m-3 my-4">
      <Img fluid={img} />
      <Card.Body>
        <Card.Title className="m-0"><b className="m-0">{title}</b></Card.Title>
      </Card.Body>
      <Link to={link} className="stretched-link"/>
    </Card>
  </Col>
)

export default IndexPage

